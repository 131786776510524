<template>
  <div>
    <!-- 内容 -->
    <div class="orderdetailsbox">
      <div class="orderback">
        <a href="javascript:void(0);" @click="headback()"
          ><i class="el-icon-arrow-left"></i> 返回</a
        >
        <span>/</span>
        <h5>订单详情</h5>
      </div>
      <div class="orderdetailsup">
        <ul class="clearfloat">
          <li class="active">
            <h5><span>1</span></h5>
            <p>买家下单</p>
            <strong>{{ orderdetailscon.created_at }}</strong>
          </li>
          <li :class="orderdetailscon.is_pay == 1 ? 'active' : ''">
            <h5><span>2</span></h5>
            <p>买家付款</p>
            <strong>{{ orderdetailscon.pay_time }}</strong>
          </li>
          <li :class="orderdetailscon.is_send > 0 ? 'active' : ''">
            <h5><span>3</span></h5>
            <p>卖家发货</p>
            <strong>{{ orderdetailscon.send_time }}</strong>
          </li>
          <li :class="orderdetailscon.is_confirm == 1 ? 'active' : ''">
            <h5><span>4</span></h5>
            <p>交易完成</p>
            <strong>{{ orderdetailscon.confirm_time }}</strong>
          </li>
        </ul>
      </div>
      <div class="orderdetailscon">
        <ul class="clearfloat d-flex">
          <li>
            <div class="orderdetailstitle clearfloat">
              <h5>订单信息</h5>
              <div class="orderdetailsright">
                <a
                  href="javascript:void(0);"
                  v-if="
                    (orderdetailscon.is_send == 0 ||
                      orderdetailscon.is_send == 2) &&
                    orderdetailscon.is_pay == 1 &&
                    hasPerm(['order.send'])
                  "
                  class="consignment"
                  @click="orderdeliver(orderdetailscon)"
                  >发货</a
                >
                <!-- <a href="javascript:void(0);">打印订单</a> -->
                <a
                  href="javascript:void(0);"
                  class="orderbz"
                  @click="attributed(orderdetailscon)"
                  v-if="hasPerm(['order.update.remark'])"
                  >备注 <i v-if="orderdetailscon.words"></i
                ></a>
              </div>
            </div>
            <div class="orderdetailsbut">
              <!-- <p class="clearfloat">
								<span>订单状态</span>
								<strong>等待发货</strong>
							</p> -->
              <p class="clearfloat">
                <span>订单编号</span>
                <strong
                  >{{ orderdetailscon.order_no }}
                  <a
                    href="javascript:void(0);"
                    class="el-icon-document-copy"
                    @click="copy(orderdetailscon.order_no)"
                  ></a
                ></strong>
              </p>
              <p class="clearfloat">
                <span>订单类型</span>
                <!-- <strong>{{
                  common_info["goods.vr_type.map"][orderdetailscon.vr_type]
                }}</strong> -->
                <strong>{{ orderdetailscon.application_name }}</strong>
              </p>
              <p class="clearfloat">
                <span>下单时间</span>
                <strong>{{ orderdetailscon.created_at }}</strong>
              </p>
              <p class="clearfloat">
                <span>订单来源</span>
                <!-- <strong>{{common_info['order.source.type.map'][orderdetailscon.order_source]}}</strong> -->
                <template v-if="orderdetailscon.order_source_name">{{
                  orderdetailscon.order_source_name
                }}</template>
                <template v-if="orderdetailscon.order_source2_name"
                  >-{{ orderdetailscon.order_source2_name }}</template
                >
              </p>
              <p class="clearfloat">
                <span>用户名</span>
                <strong>{{ orderdetailscon.purchaser_name }}</strong>
              </p>
              <p class="clearfloat">
                <span>支付方式</span>
                <strong>{{
                  common_info["order.payment.type.map"][
                    orderdetailscon.pay_type2
                  ]
                }}</strong>
              </p>
              <p class="clearfloat" v-if="orderdetailscon.pay_time">
                <span>支付时间</span>
                <strong>{{ orderdetailscon.pay_time }}</strong>
              </p>
              <p class="clearfloat" v-if="orderdetailscon.send_time">
                <span>发货时间</span>
                <strong>{{ orderdetailscon.send_time }}</strong>
              </p>
              <p class="clearfloat" v-if="orderdetailscon.confirm_time">
                <span>确认收货时间</span>
                <strong>{{ orderdetailscon.confirm_time }}</strong>
              </p>
            </div>
          </li>
          <li>
            <div class="orderdetailstitle clearfloat">
              <h5>买家及收货信息</h5>
              <div class="orderdetailsright">
                <a
                  href="javascript:void(0);"
                  @click="reviseaddress(orderdetailscon, citylisted)"
                  v-if="hasPerm(['order.update.address'])"
                  >修改信息</a
                >
              </div>
            </div>
            <div class="orderdetailsbut">
              <p class="clearfloat">
                <span>买家</span>
                <strong>{{ orderdetailscon.purchaser_name }}</strong>
              </p>
              <p class="clearfloat">
                <span>买家备注</span>
                <strong>{{ orderdetailscon.remark }}</strong>
              </p>
              <p class="clearfloat">
                <span>配送方式</span>
                <strong>{{
                  orderdetailscon.is_offline == 1 ? "自提" : "普通快递"
                }}</strong>
              </p>
              <p class="clearfloat">
                <span>收货人</span>
                <strong>{{ orderdetailscon.receiver_name }}</strong>
              </p>
              <p class="clearfloat">
                <span>手机号码</span>
                <strong>{{ orderdetailscon.receiver_mobile }}</strong>
              </p>
              <p class="clearfloat">
                <span>收货地址</span>
                <strong>{{ orderdetailscon.address }}</strong>
              </p>
            </div>
          </li>
          <li>
            <div class="orderdetailstitle clearfloat">
              <h5>发票信息</h5>
              <div
                class="orderdetailsright"
                v-show="orderdetailscon.apply_invoice != 0"
              >
                <a href="javascript:void(0);" class="consignment">下载发票</a>
              </div>
            </div>
            <div
              class="orderdetailsbut orderwei"
              v-show="orderdetailscon.apply_invoice == 0"
            >
              <p>未申请开发票</p>
            </div>
            <template>
              <div
                class="orderdetailsbut"
                v-show="orderdetailscon.apply_invoice == 1"
              >
                <p class="clearfloat">
                  <span>抬头类型</span>
                  <strong>{{
                    orderdetailscon.invoice &&
                    Object.keys(orderdetailscon.invoice).length > 0 &&
                    orderdetailscon.invoice.head_type == 1
                      ? "个人"
                      : "企业"
                  }}</strong>
                </p>
                <p class="clearfloat">
                  <span>发票类型</span>
                  <strong
                    v-if="
                      orderdetailscon.invoice &&
                      orderdetailscon.invoice.invoice_type == 0
                    "
                    >无</strong
                  >
                  <strong
                    v-if="
                      orderdetailscon.invoice &&
                      orderdetailscon.invoice.invoice_type == 1
                    "
                    >增值税普通票</strong
                  >
                  <strong
                    v-if="
                      orderdetailscon.invoice &&
                      orderdetailscon.invoice.invoice_type == 2
                    "
                    >增值税专用票</strong
                  >
                </p>
                <p class="clearfloat">
                  <span>发票抬头</span>
                  <strong>{{
                    orderdetailscon.invoice &&
                    Object.keys(orderdetailscon.invoice).length > 0 &&
                    orderdetailscon.invoice.header
                  }}</strong>
                </p>
                <p class="clearfloat">
                  <span>纳税人识别码</span>
                  <strong>{{
                    orderdetailscon.invoice &&
                    Object.keys(orderdetailscon.invoice).length > 0 &&
                    JSON.parse(
                      JSON.stringify(orderdetailscon.invoice.invoice_content)
                    ).taxpayer
                  }}</strong>
                </p>
                <p class="clearfloat">
                  <span>开户行</span>
                  <strong>{{
                    orderdetailscon.invoice &&
                    Object.keys(orderdetailscon.invoice).length > 0 &&
                    JSON.parse(
                      JSON.stringify(orderdetailscon.invoice.invoice_content)
                    ).bank_num
                  }}</strong>
                </p>
                <p class="clearfloat">
                  <span>银行账户</span>
                  <strong>{{
                    orderdetailscon.invoice &&
                    Object.keys(orderdetailscon.invoice).length > 0 &&
                    JSON.parse(
                      JSON.stringify(orderdetailscon.invoice.invoice_content)
                    ).bank_account
                  }}</strong>
                </p>
                <p class="clearfloat">
                  <span>注册电话</span>
                  <strong>{{
                    orderdetailscon.invoice &&
                    Object.keys(orderdetailscon.invoice).length > 0 &&
                    JSON.parse(
                      JSON.stringify(orderdetailscon.invoice.invoice_content)
                    ).register_tel
                  }}</strong>
                </p>
                <p class="clearfloat">
                  <span>注册地址</span>
                  <strong>{{
                    orderdetailscon.invoice &&
                    Object.keys(orderdetailscon.invoice).length > 0 &&
                    JSON.parse(
                      JSON.stringify(orderdetailscon.invoice.invoice_content)
                    ).register_address
                  }}</strong>
                </p>
              </div>
            </template>
          </li>
        </ul>
      </div>
      <div class="orderdetailsbuton">
        <div class="orderdetailstitle clearfloat">
          <h5>商品信息</h5>
        </div>
        <div class="orderdetailtable">
          <div class="orderdetailtabletitle clearfloat">
            <p>商品</p>
            <p>单价</p>
            <p>购买数量</p>
            <p>已发货数量</p>
            <p v-if="orderdetailscon.application != 'platform_fl'">优惠</p>
            <p v-else>支付方式</p>
            <p>小计</p>
            <p>物流信息</p>
            <p>状态</p>
          </div>
          <div class="orderdetailtablecon">
            <div v-if="!orderdetailscon.send_info">
              <div
                class="orderdetailtablelist d-flex align-items-center"
                v-for="(item, index) in orderdetailscon.order_detail"
                :key="index"
              >
                <div class="orderdetail-item">
                  <div
                    class="addorderdetail d-flex align-items-center flex-wrap"
                  >
                    <div class="addsend d-flex align-items-center">
                      <div
                        class="addorderdetail-item order_itemlist d-flex align-items-center"
                      >
                        <div class="order_itemimg">
                          <img :src="item.pic" alt="" />
                        </div>
                        <div class="order_itemtext">
                          <a href="javascript:void(0);">{{
                            item.goods_name
                          }}</a>
                          <div class="orderguige clearfloat">
                            <p
                              v-for="(itemed, indexed) in item.attr"
                              :key="indexed"
                            >
                              <span>{{ itemed.specs_key }}：</span
                              >{{ itemed.specs_value }}
                            </p>
                          </div>
                          <div class="orderSupplier">
                            供货商名称：{{ orderdetailscon.supplier?.name }}
                          </div>
                        </div>
                      </div>
                      <div class="addorderdetail-item">
                        <div class="orderdetail-itemtext">
                          <p
                            v-if="orderdetailscon.application != 'platform_fl'"
                          >
                            ￥{{ item.price }}
                          </p>
                          <p v-else>{{ item.price }}积分</p>
                        </div>
                      </div>
                      <div class="addorderdetail-item">
                        <div class="orderdetail-itemtext">
                          <p>{{ item.num }}</p>
                        </div>
                      </div>
                      <div class="addorderdetail-item">
                        <div class="orderdetail-itemtext">
                          <p>{{ item.send_num }}</p>
                        </div>
                      </div>
                      <div class="addorderdetail-item">
                        <div class="orderdetail-itemtext">
                          <!-- <p>会员折扣：{{ item.discount }}折</p> -->
                          <!-- <p>优惠：{{item.consumer_price}}元</p> -->
                          <p
                            v-if="orderdetailscon.application != 'platform_fl'"
                          >
                            会员折扣：{{ item.discount }}折
                          </p>
                          <p v-else>
                            {{
                              common_info["order.payment.pay_type.map"][
                                orderdetailscon.pay_type2
                              ]
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="addorderdetail-item">
                        <div class="orderdetail-itemtext">
                          <p
                            v-if="orderdetailscon.application != 'platform_fl'"
                          >
                            ￥{{ item.total_price }}
                          </p>
                          <p v-else>{{ item.total_price }}积分</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="orderdetail-item">
                  <div class="addorderlast">
                    <div class="orderdetail-itemtext">
                      <p>暂无物流</p>
                    </div>
                  </div>
                  <div class="addorderlast">
                    <div class="orderdetail-itemtext">
                      <p>未发货</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 已发货 -->
            <div>
              <div>
                <div
                  class="orderdetailtablelist d-flex align-items-center"
                  v-for="(item, index) in orderdetailscon.send_info"
                  :key="index"
                >
                  <div class="orderdetail-item">
                    <div
                      class="addorderdetail d-flex align-items-center flex-wrap"
                    >
                      <div
                        class="addsend d-flex align-items-center"
                        v-for="(items, indexs) in item.goods_info"
                        :key="indexs"
                      >
                        <div
                          class="addorderdetail-item order_itemlist d-flex align-items-center"
                        >
                          <div class="order_itemimg">
                            <img :src="items.pic" alt="" />
                          </div>
                          <div class="order_itemtext">
                            <a href="javascript:void(0);">{{
                              items.goods_name
                            }}</a>
                            <div class="orderguige clearfloat">
                              <p
                                v-for="(itemed, indexed) in items.attr"
                                :key="indexed"
                              >
                                <span>{{ itemed.specs_key }}：</span
                                >{{ itemed.specs_value }}
                              </p>
                            </div>
                            <div class="orderSupplier">
                              供货商名称：{{ orderdetailscon.supplier?.name }}
                            </div>
                          </div>
                        </div>
                        <div class="addorderdetail-item">
                          <div class="orderdetail-itemtext">
                            <p
                              v-if="
                                orderdetailscon.application != 'platform_fl'
                              "
                            >
                              ￥{{ items.price }}
                            </p>
                            <p v-else>{{ item.price }}积分</p>
                          </div>
                        </div>
                        <div class="addorderdetail-item">
                          <div class="orderdetail-itemtext">
                            <p>{{ items.num }}</p>
                          </div>
                        </div>
                        <div class="addorderdetail-item">
                          <div class="orderdetail-itemtext">
                            <p>{{ items.send_num }}</p>
                          </div>
                        </div>
                        <div class="addorderdetail-item">
                          <div class="orderdetail-itemtext">
                            <!-- <p>优惠：{{items.consumer_price}}元</p> -->
                            <p
                              v-if="
                                orderdetailscon.application != 'platform_fl'
                              "
                            >
                              会员折扣：{{ items.discount }}折
                            </p>
                            <p v-else>
                              {{
                                common_info["order.payment.pay_type.map"][
                                  orderdetailscon.pay_type2
                                ]
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="addorderdetail-item">
                          <div class="orderdetail-itemtext">
                            <p
                              v-if="
                                orderdetailscon.application != 'platform_fl'
                              "
                            >
                              ￥{{ items.total_price }}
                            </p>
                            <p v-else>{{ items.total_price }}积分</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="orderdetail-item">
                    <div class="addorderlast">
                      <div class="orderdetail-itemtext">
                        <p v-if="!item.express_no">自提</p>
                        <p
                          v-else
                          class="logistics"
                          @click="loopordertracked(item)"
                        >
                          查看物流
                        </p>
                      </div>
                    </div>
                    <div class="addorderlast">
                      <div class="orderdetail-itemtext">
                        <p>{{ item.express_info }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 未发货 -->
              <div
                class="orderdetailtablelist d-flex align-items-center"
                v-for="(item, index) in orderdetailscon.no_send_info"
                :key="index"
              >
                <div class="orderdetail-item">
                  <div class="addorderdetail d-flex align-items-center">
                    <div
                      class="addorderdetail-item order_itemlist d-flex align-items-center"
                    >
                      <div class="order_itemimg">
                        <img :src="item.pic" alt="" />
                      </div>
                      <div class="order_itemtext">
                        <a href="javascript:void(0);">{{ item.goods_name }}</a>
                        <div class="orderguige clearfloat">
                          <p
                            v-for="(itemed, indexed) in item.attr"
                            :key="indexed"
                          >
                            <span>{{ itemed.specs_key }}：</span
                            >{{ itemed.specs_value }}
                          </p>
                        </div>
                        <div class="orderSupplier">
                          供货商名称：{{ orderdetailscon.supplier?.name }}
                        </div>
                      </div>
                    </div>
                    <div class="addorderdetail-item">
                      <div class="orderdetail-itemtext">
                        <p v-if="orderdetailscon.application != 'platform_fl'">
                          ￥{{ item.price }}
                        </p>
                        <p v-else>{{ item.price }}积分</p>
                      </div>
                    </div>
                    <div class="addorderdetail-item">
                      <div class="orderdetail-itemtext">
                        <p>{{ item.num }}</p>
                      </div>
                    </div>
                    <div class="addorderdetail-item">
                      <div class="orderdetail-itemtext">
                        <p>0</p>
                      </div>
                    </div>
                    <div class="addorderdetail-item">
                      <div class="orderdetail-itemtext">
                        <p v-if="orderdetailscon.application != 'platform_fl'">
                          会员折扣：{{ item.discount }}折
                        </p>
                        <!-- <p>优惠：{{item.consumer_price}}元</p> -->
                        <p v-else>
                          {{
                            common_info["order.payment.pay_type.map"][
                              orderdetailscon.pay_type2
                            ]
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="addorderdetail-item">
                      <div class="orderdetail-itemtext">
                        <p v-if="orderdetailscon.application != 'platform_fl'">
                          ￥{{ item.total_price }}
                        </p>
                        <p v-else>{{ item.total_price }}积分</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="orderdetail-item">
                  <div class="addorderlast">
                    <div class="orderdetail-itemtext">
                      <p>暂无物流</p>
                    </div>
                  </div>
                  <div class="addorderlast">
                    <div class="orderdetail-itemtext">
                      <p>{{ item.express_info }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="orderdetailast clearfloat">
          <div class="orderdetailastright">
            <p>
              <b>商品数量</b> <span>{{ orderdetailscon.total }}件</span>
            </p>
            <p>
              <b>运费</b> <span>{{ orderdetailscon.express_price }}元</span>
            </p>
            <p v-if="orderdetailscon.application != 'platform_fl'">
              <b>总金额</b> <strong>{{ orderdetailscon.total_price }}元</strong>
            </p>
            <template v-else>
              <p>
                <b>积分</b>
                <strong>{{ orderdetailscon.account_price }}</strong>
              </p>
              <p>
                <b>现金</b>
                <strong>{{ orderdetailscon.money_price }}元</strong>
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 立即发货弹层 -->
    <orderdelivery
      ref="deliverybox"
      @delsuccess="delivesuccess"
    ></orderdelivery>

    <!-- 备注弹层 -->
    <attribute ref="attributetc" @certitude="handleCertitude"></attribute>

    <!-- 修改地址弹层 -->
    <orderadress ref="addresstc" @adresscertitude="handleaddress"></orderadress>

    <!-- 查看物流 -->
    <el-dialog
      title="物流信息"
      class="logisticstc"
      :visible.sync="logisticstype"
    >
      <div class="wuliulist">
        <div class="wuliutop">
          <p>
            {{ express }} <span>{{ expressnumber }}</span>
            <strong @click="copy(expressnumber)">复制</strong>
          </p>
          <p v-if="orderSingle.words">发货备注：{{ orderSingle.words }}</p>
        </div>
        <div class="wuliubut">
          <ul>
            <li
              class="clearfloat"
              v-for="(item, index) in tracklist"
              :key="index"
              :class="index == 0 ? 'active' : ''"
            >
              <i></i>
              <div class="wuliutext">
                <h5>{{ item.context }}</h5>
                <p>{{ item.time }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="" style="margin-top: 10px;">
          <el-button @click.stop="amendExpress"
            >修改物流</el-button
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="logisticstype = false">取 消</el-button>
        <el-button @click="logisticstype = false" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <template v-if="orderdetailscon.application == 'platform_fl' && false">
      <!-- 员工信息 -->
      <el-dialog
        title="员工信息"
        class="employee"
        :visible.sync="employVisible"
        width="30%"
      >
        <div class="employee_content">
          <div class="employee_single d-flex align-items-center">
            <div>员工账号：</div>
            <div>{{ orderdetailscon?.user?.username }}</div>
          </div>
          <div class="employee_single d-flex align-items-center">
            <div>手机号：</div>
            <div>{{ orderdetailscon?.user?.mobile }}</div>
          </div>
          <div class="employee_single d-flex align-items-center">
            <div>身份证号：</div>
            <div>{{ orderdetailscon?.user?.id_card || "无" }}</div>
          </div>
          <div class="employee_single d-flex align-items-center">
            <div>部门：</div>
            <div>{{ orderdetailscon?.user?.department?.name || "无" }}</div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="employVisible = !employVisible"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </template>
    <!-- 修改物流 -->
    <el-dialog title="修改物流" :visible.sync="editExpressVisible" width="40%">
      <div class="modification ">
        <div class="modification_item">
          <p>物流公司</p>
          <el-select v-model="expressInfo"  filterable placeholder="请选择">
            <el-option v-for="(item,index) in common_info['express.delivery.list']" :key="index" :label="item.value"
												:value="item.value"></el-option>
          </el-select>
        </div>
        <div class="modification_item">
          <p>物流单号</p>
          <el-input v-model="expressNo" placeholder="请输入快递单号"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editExpressVisible = false">取 消</el-button>
        <el-button @click="ExpressSubmit" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.query.id,
      address: false, //控制修改地址是否显示
      addresssave: "", //省修改地址
      addresscity: "", //市修改地址
      addressclassify: "", //区修改地址
      deliverytype: false, //控制立即发货是否显示
      deliverychecked: false, //发货弹层全选状态
      radio: "1", //判断是否需要物流
      demail: "", //快递
      remarkstype: false, //控制备注是否显示
      textareaValue: "", //备注
      logisticstype: false, //物流信息显示
      orderdetailscon: "", //订单详情数据
      citylisted: [], //省市区
      express: "",
      expressnumber: "",
      tracklist: [],
      employVisible: false,
      orderSingle: "",
      editExpressVisible: false,
      expressInfo:'', //物流公司
      expressNo:'', //快递单号
    };
  },
  beforeCreate() {
    this.$store.commit("commonInfo", "");
  },
  created() {
    let data = {
      keys: [
        "goods.vr_type.map",
        "order.source.type.map",
        "order.payment.type.map",
        "express.delivery.list",
        "order.source.map",
        "order.payment.pay_type.map",
      ],
    };
    this.common.getCommonInfo(this, data);
  },
  computed: {
    common_info: {
      get() {
        let stateuser = {
          "goods.vr_type.map": [], //订单类型
          "order.source.type.map": [], //订单来源
          "order.payment.type.map": [], //支付方式
          "express.delivery.list": [], //物流公司
          "order.source.map": {},
          "order.payment.pay_type.map": [],
        };
        let val = this.$store.state.common_info || stateuser;
        return val;
      },
      set() {},
    },
  },
  mounted() {
    this.orderdetailapi(this.id);
    this.citylistapi();
  },
  methods: {
    orderdetailapi(id) {
      this.$get(this.$apis.orderDetail + id)
        .then((res) => {
          // console.log(res)
          if (res.code == 200) {
            let order_source_list = this.common_info["order.source.map"];
            for (var i in order_source_list) {
              if (res.data.application && res.data.application == i) {
                res.data.application_name = order_source_list[i].label;
                if (res.data.order_source && order_source_list[i].children) {
                  for (var j in order_source_list[i].children) {
                    if (res.data.order_source == j) {
                      res.data.order_source_name =
                        order_source_list[i].children[j].label;
                      if (
                        res.data.order_source2 &&
                        order_source_list[i].children
                      ) {
                        for (var a in order_source_list[i].children[j]
                          .children) {
                          if (res.data.order_source2 == a) {
                            res.data.order_source2_name =
                              order_source_list[i].children[j].children[
                                a
                              ].label;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            // console.log(order_source_list,res.data)
            this.orderdetailscon = res.data;
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },

    // 备注
    attributed(val) {
      this.$refs.attributetc.open(val);
    },
    // 备注请求
    handleCertitude(val) {
      if (!val.words) {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请填写备注信息",
        });
        return false;
      }
      let data = {
        remark: val.words,
      };
      this.$put(this.$apis.orderattrbuite + val.id, data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "添加备注成功",
            });
            this.$refs.attributetc.closeattrubute();
            this.orderdetailapi(this.id);
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 查看物流
    loopordertracked(item) {
      this.express = item.express;
      this.expressnumber = item.express_no;
      this.logisticstype = true;
      this.orderSingle = item;
      this.$get(this.$apis.ordertrack + item.send_id)
        .then((res) => {
          if (res.code == 200) {
            this.tracklist = res.data.list;
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 省市区
    citylistapi() {
      this.$get(this.$apis.cityList)
        .then((res) => {
          // console.log(res)
          if (res.code == 200) {
            this.citylisted = res.data;
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 修改地址
    reviseaddress(val, city) {
      // console.log(val)
      this.$refs.addresstc.open(val, city);
    },
    // 修改地址请求
    handleaddress(val) {
      let data = {
        name: val.receiver_name, //收货人姓名
        mobile: val.receiver_mobile, //收货人电话
        province: val.province, //省份信息
        city: val.city, //城市信息
        district: val.district, //区县信息
        address: val.address, //详细地址
      };
      this.$put(this.$apis.addressedit + val.id, data)
        .then((res) => {
          // console.log(res)
          if (res.code == 200) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "修改成功",
              onClose: () => {
                this.$refs.addresstc.closeattrubute();
                this.orderlistapi();
              },
            });
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 立即发货
    orderdeliver(val) {
      this.loopdeliveryapi(val.id);
    },
    // 提交发货
    delivesuccess() {
      this.$message({
        message: "发货成功",
        type: "success",
        offset: 200,
        duration: 1500,
      });
      this.$refs.deliverybox.closeattrubute();
      this.orderdetailapi(this.id);
    },
    loopdeliveryapi(id) {
      this.$get(this.$apis.loopdelivery + id)
        .then((res) => {
          if (res.code == 200) {
            this.$refs.deliverybox.open(
              res.data,
              this.common_info["express.delivery.list"]
            );
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },

    copy(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success",
      });
      oInput.remove();
    },
    handleChange(value) {
      console.log(value);
    },
    headback() {
      window.history.go(-1);
    },
    amendExpress(){
      this.editExpressVisible = true;
      this.logisticstype = false;
      this.expressInfo= this.orderSingle.express;
      this.expressNo=this.orderSingle.express_no;
    },
    // 修改物流
    ExpressSubmit() {
      let that = this;
      if(!this.expressInfo){
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请选择物流公司",
        });
        return false
      }
      if(!this.expressNo){
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请填写快递单号",
        });
        return false
      }
      let data = {
        express: this.expressInfo,
        express_no:this.expressNo,
      };
      this.$put(this.$apis.editExpress+this.orderSingle.send_id, data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
              offset: 200,
              duration: 1500,
            });
            that.editExpressVisible=false;
            that.orderdetailapi(that.id);
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          this.common.message(this, err.message);
        });
    },
  },
};
</script>

<style scoped>
@import url("css/order.css");
.orderwei p {
  text-align: center;
  margin: 40px auto;
}
.orderdetailsright a.consignment {
  margin-left: 6px;
}
.orderbz {
  position: relative;
}
.orderbz i {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 2px;
  right: 2px;
}
</style>
<style lang="scss" scoped>
.employee {
  .employee_content {
    padding: 20px;
    .employee_single {
      margin: 20px 0;
      div {
        font-size: 16px;
        color: #333;
      }
      div:first-child {
        width: 160px;
        text-align: right;
      }
    }
  }
}

.orderSupplier {
  margin-top: 5px;
}
.modification{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  .modification_item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    p{
      width: 160px;
      text-align: right;
      margin-right: 10px;
    }
    /deep/.el-select{
      width: 88%;
      .el-input{
        width: 100%;
      }
    }
  }
  .modification_item:nth-child(n+2){
    margin-top: 15px;
    /deep/.el-input{
      width: 88%;
    }
  }
}
</style>